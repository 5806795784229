import React from 'react';

import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import SEO from '../components/misc/seo';

function Behandlungskonzept() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="bg-white text-green-800">
        <div className="py-24">
          <Container>
            <div className="text-center mb-12">
              <h1 className="font-serif text-4xl sm:text-6xl">
                Behandlungskonzept
              </h1>
            </div>
            <div className="md:max-w-4xl mx-auto">
              <p>
                Mein heilpädagogisches Behandlungskonzept setzt dort an, wo
                Teilhabe unter erschwerten Bedingungen erfolgt. Es versteht sich
                als ein ganzheitlicher Ansatz, der die individuellen Fähigkeiten
                und Möglichkeiten des Kindes berücksichtigt.
              </p>
              <p>
                Jeder Mensch ist wertvoll und einzigartig. Heilpädagogisches
                Handeln ist ressourcenorientiert und zielt auf die
                gleichberechtigte Teilhabe eines jeden Menschen am
                gesellschaftlichen Leben ab. Einfühlungsvermögen und Wärme im
                Kontakt miteinander sind notwendig, um die Wünsche und
                Bedürfnisse von Kindern mit besonderem Förderbedarf zu erkennen
                und sinnvoll darauf eingehen zu können.
              </p>
              <p>
                Dabei werden immer die einzelnen Entwicklungsbereiche des Kindes
                (körperlich, seelisch, geistig und Sinneswahrnehmungen) in ihrer
                Wechselwirkung zum sozialen Umfeld wie Familie, Kindergarten und
                Freunde beachtet, mit dem Ziel der Inklusion.
              </p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export default Behandlungskonzept;
